import { PaymentCardType } from "./interfaces/PaymentIntent.interface";
import { ICurrency } from "./interfaces/SubscriptionPlans";

import amexIcon from "@/assets/card-brands/amex.png";
import mastercardIcon from "@/assets/card-brands/mastercard.png";
import visaIcon from "@/assets/card-brands/visa.png";
import dinersIcon from "@/assets/card-brands/diners.png";
import jcbIcon from "@/assets/card-brands/jcb.png";
import discoverIcon from "@/assets/card-brands/discover.png";
import cartesBancairesIcon from "@/assets/card-brands/cartes-bancaires.png";
import unionPayIcon from "@/assets/card-brands/unionPay.png";

export const BASE_API_URL = import.meta.env.VITE_BASE_API_URL || "http://localhost:8000/api";
export const RAG_DOCUMENTATION_URL = import.meta.env.VITE_RAG_DOCUMENTATION_URL || "";

export const SUPPORT_EMAIL = import.meta.env.VITE_SUPPORT_EMAIL || "";
export const HOURLY_ANALYTICS_THRESHOLD = import.meta.env.VITE_HOURLY_ANALYTICS_THRESHOLD || 3;

export const STRIPE_PUBLIC_KEY = import.meta.env.VITE_STRIPE_PUBLIC_KEY || "pk_test_MyfdcgVB5Y977WC4frDNsvZ200Qd4AGQx9";
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN || "";

// Auth
export const REFRESH_TOKEN_INTERVAL_MILLISECONDS = 60 * 1000; // 1 minute

export const AUTH_FORM_LABEL_COL = 7;
export const AUTH_FORM_WRAPPER_COL = 17;
export const AUTH_FORM_MAX_WIDTH = 800;

export const AUTH_TOKEN_NOT_VALID = "token_not_valid";

// Storage Keys
export const USER_LOCAL_STORAGE_KEY = "rb_user";

// Locales
export const locales = {
  en: "en",
  de: "de",
};

export const DEFAULT_DATETIME_FORMAT = "YYYY-MM-DD";

export const CURRENCY_SYMBOLS: Record<ICurrency, Record<"title" | "icon", string>> = {
  eur: {
    title: "Euro",
    icon: "&euro;",
  },
  usd: {
    title: "US Dollars",
    icon: "&dollar;",
  },
  gbp: {
    title: "GB Pound",
    icon: "&pound;",
  },
};

export const MONTHS_MAP = {
  0: "months.january",
  1: "months.february",
  2: "months.march",
  3: "months.april",
  4: "months.may",
  5: "months.june",
  6: "months.july",
  7: "months.august",
  8: "months.september",
  9: "months.october",
  10: "months.november",
  11: "months.december",
};

// Navigation routes
export const NAV_ROOT = "/";
export const NAV_LOGIN = "/login";
export const NAV_LOGOUT = "/logout";
export const NAV_REGISTRATION = "/registration";
export const NAV_FORGOT_PASSWORD = "/forgot-password";
export const NAV_RESET_PASSWORD = "/forgot-password/:token";
export const NAV_EMAIL_CONFIRMATION = "/email-confirmation/:token";

export const NAV_CHECKOUT = "/workspace/:workspaceId/project/:projectId/checkout";
export const NAV_CHECKOUT_SESSION = "/workspace/:workspaceId/project/:projectId/checkout/:checkoutSessionId";
export const NAV_PROJECTS = "/workspace/:workspaceId?";
export const NAV_PROJECT_SINGLE_VIEW = "/workspace/:workspaceId/project/:projectId";

export const NAV_SUBSCRIPTION_PLANS = "/workspace/:workspaceId/project/:projectId/subscription-plans";

// PROFILE RELATED PAGES
export const NAV_PROFILE = "/my-profile";
export const NAV_EDIT_PROFILE = "/edit-profile";

// SUBSCRIPTIONS RELATED PAGES
export const NAV_SUBSCRIPTIONS = "/subscriptions";

export const NAV_EXAMPLES = "/workspace/examples";

export const CARD_BRANDS: PaymentCardType = {
  amex: {
    brand: "American Express",
    icon: amexIcon,
  },
  mastercard: {
    brand: "MasterCard",
    icon: mastercardIcon,
  },
  visa: {
    brand: "Visa",
    icon: visaIcon,
  },
  diners: {
    brand: "Diners Club",
    icon: dinersIcon,
  },
  jcb: {
    brand: "JCB",
    icon: jcbIcon,
  },
  discover: {
    brand: "Discover",
    icon: discoverIcon,
  },
  cartes_bancaires: {
    brand: "Cartes Bancaires",
    icon: cartesBancairesIcon,
  },
  unionpay: {
    brand: "UnionPay",
    icon: unionPayIcon,
  },
};

export const PAGINATION_OPTIONS = { defaultPageSize: 7, pageSizeOptions: [7, 10, 20, 50] };
