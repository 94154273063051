import { ReactNode } from "react";

// components
import { Layout, Space, Image } from "antd";
import Logo from "@/assets/logo_light.svg";

// misc
const { Footer, Content } = Layout;
const baseClassName = "auth-layout"
/**
 * The MainLayout component serves as the main layout structure for your application.
 *
 * @param {ReactNode} props.children - The child components or content to be rendered within the layout.
 * @returns {JSX.Element} The rendered MainLayout component.
 */
export const AuthLayout = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  return (
    <Space direction="vertical" style={{ width: "100%" }} size={[0, 48]}>
      <Layout className={baseClassName} style={{ position: "relative", minHeight: "100dvh", background: "#252731"}}>
        <div className="bg-item orange-item"></div>
        <div className="bg-item purple-item"></div>
        <div className="bg-item turquoise-item"></div>
        <div className={`${baseClassName}__logo-wrapper`}>
          <Image className={`${baseClassName}__logo`} width={160} src={Logo} preview={false} alt="RAG-logo" />
        </div>
        
        <Content className="main-content d-flex justify-content-center position-relative flex-column">
          {children}
          <Footer className="w-100vw position-absolute bottom-0 auth-footer">
            &copy; {new Date().getFullYear()} Helvia IKE
          </Footer>
        </Content>
      </Layout>
    </Space>
  );
};
