import React from "react";
import ReactDOM from "react-dom/client";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";

import { queryClient } from "./utils/queryClient.ts";
import { I18nextProvider } from "react-i18next";
import * as Sentry from "@sentry/react";
import { BASE_API_URL, SENTRY_DSN } from "./constants.ts";

import i18n from "./i18n";
import App from "./App.tsx";
import { App as AntdApp } from "antd";

import { BrowserRouter as Router } from "react-router-dom";

const  persister = createSyncStoragePersister({
  storage: window.localStorage,
});

Sentry.init({
  enabled: SENTRY_DSN && import.meta.env.PROD,
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.replayIntegration()
  ],
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", BASE_API_URL],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{
          persister: persister,
          // maxAge: ,
          // dehydrateOptions: {
          // shouldDehydrateQuery: ({queryKey}) =>  queryKey.includes('randomQuote')
          // }
        }}
      >
        {/* <AuthProvider> */}
        <AntdApp>
          <Router>
            <App />
          </Router>
        </AntdApp>
        {/* </AuthProvider> */}
        <ReactQueryDevtools />
      </PersistQueryClientProvider>
    </I18nextProvider>
  </React.StrictMode>
);
