import { ReactNode, useEffect } from "react";
import { Layout, Space } from "antd";
import i18n from "@/i18n";

// components
import { HeaderBar } from "../HeaderBar";

// misc
import { queryClient } from "@/utils/queryClient";
import { ITokens } from "@/interfaces/User.interface";
import { useUserInfo } from "@/api/user";
import { tokens as Tokens } from "@/utils/tokens";

const { Footer, Content } = Layout;

const baseClassName = "main-layout";
/**
 * The MainLayout component serves as the main layout structure for your application.
 *
 * @param {ReactNode} props.children - The child components or content to be rendered within the layout.
 * @returns {JSX.Element} The rendered MainLayout component.
 */
export const MainLayout = ({ children }: { children: ReactNode }): JSX.Element => {
  const tokensInstance = Tokens;
  const userAuth:ITokens | undefined = queryClient.getQueryData(["user-auth"]);
  const { access: accessToken } = userAuth || {};
  const { data: userData } = useUserInfo(tokensInstance.getTokenSub(accessToken) as string);
  const availableLanguages = Object.keys(i18n?.options?.resources || {});

  // If we have language in userData make it default language
  
  useEffect(() => {
    if (userData?.language && availableLanguages.includes(userData?.language)) {
      i18n.changeLanguage(userData?.language)
    } 
  }, [userData?.language, availableLanguages])
  return (
    <Space direction="vertical" style={{ width: "100%" }} size={[0, 48]}>
    <Layout className={baseClassName} style={{ minHeight: "100vh" }}>
        <HeaderBar key="header-bar" />
        <div className="bg-item right-item"></div>
        <div className="bg-item left-item"></div>
        <div className="bg-item bottom-item"></div>
        <Content className="main-content position-relative">
          { children }
          <Footer className="w-100vw position-absolute bottom-0">&copy; {new Date().getFullYear()} Helvia IKE</Footer>
        </Content>
      </Layout>
    </Space>
  );
};
