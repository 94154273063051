import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import i18n from "@/i18n";

// components imports
import { Button, Checkbox, Form, Input } from "antd";
import { Link } from "react-router-dom";

// misc imports
import { useRegisterUser } from "@/api/user";
import { NAV_LOGIN } from "@/constants";
import { ErrorsComponent } from "@/components/UI/ErrorsComponent";
import { IRequestError } from "@/interfaces/ServerError.interface";
import { validatePassword } from "@/utils/form/validators";
import { IUserRegistrationData } from "@/interfaces/User.interface";

type FieldType = {
  first_name?: string;
  last_name?: string;
  company?: string;
  email?: string;
  password?: string;
  confirm_password?: string;
  terms_accepted: boolean;
  privacy_policy_accepted: boolean;
};

export const RegistrationFrom = () => {
  const activeLang = i18n.resolvedLanguage || 'en';

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [userEmail, setUserEmail] = useState();
  const { mutate: registrationMutation, isLoading, error: registrationError, isSuccess: isRegistrationSuccess } = useRegisterUser();

  const validateMessages = {
    required: t("validation.required"),
    types: {
      email: t("validation.email"),
    },
    password: {
      range: t("validation.password_length"),
    },
  };

  // Validator for Password field
  const handlePasswordValidator = async (_rule: any, value: string) => {
    const errorKey = validatePassword(value);
    const formValues = form.getFieldsValue();
    if (errorKey) {
      return Promise.reject(t(errorKey));
    }

    // Check if the passwords match
    if (
      formValues.password &&
      formValues.password.trim() !== "" &&
      formValues.confirm_password &&
      formValues.confirm_password.trim() !== "" &&
      formValues.password !== formValues.confirm_password
    ) {
      return Promise.reject(t("validation.password_match"));
    }

    return Promise.resolve();
  };

  const validateForm = async (values: Record<string, any>) => {
    // Trim the form fields
    const trimmedValues: Record<string, any> = {};

    for (const key in values) {
      // if (values.hasOwnProperty(key)) {
      const value = values[key];
      if (typeof value === "string") {
        trimmedValues[key] = value.trim();
      } else {
        trimmedValues[key] = value;
      }
      // }
    }

    // Update the form values with the trimmed values
    form.setFieldsValue(trimmedValues);
    await form.validateFields();
  };

  const onFinish = async (values: Record<string, any>) => {
    try {
      await validateForm(values);
    } catch (e) {
      return; // Return as we have an error at a form field
    }

    setUserEmail(values.email);

    const { email, password, first_name, last_name, company, terms_accepted, privacy_policy_accepted } = form.getFieldsValue();
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Now you can safely submit the form
    const valuesToSubmit: IUserRegistrationData = {
      email,
      password,
      first_name,
      last_name,
      company,
      terms_accepted,
      privacy_policy_accepted,
      timezone: tz,
      language: activeLang
    };
    registrationMutation(valuesToSubmit);
  };

  // Handle successful registration
  if (isRegistrationSuccess) {
    return (
      <>
        <p>{t("registration.success", { user: userEmail })}</p>
        <Link to={NAV_LOGIN} state={{ email: userEmail }}>
          {t("common.sign_in")}
        </Link>
      </>
    );
  }

  // Registration Form
  return (
    <>
      <Form
        name="registration-form"
        layout="vertical"
        labelAlign="left"
        onFinish={onFinish}
        autoComplete="off"
        validateMessages={validateMessages}
        form={form} // Add the form instance
      >
        {/* First Name */}
        <Form.Item<FieldType>
          label={t("common.first_name")}
          name="first_name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* ./ First Name */}
        {/* Last Name */}
        <Form.Item<FieldType>
          label={t("common.last_name")}
          name="last_name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* ./ Last Name */}
        {/* Company */}
        <Form.Item<FieldType> label={t("common.company")} name="company">
          <Input />
        </Form.Item>
        {/* ./ Company */}
        {/* Email */}
        <Form.Item<FieldType>
          label={t("common.email")}
          name="email"
          rules={[
            {
              required: true,
              type: "email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* ./ Email */}
        {/* Password */}
        <Form.Item<FieldType> label={t("common.password")} name="password" rules={[{ required: true, validator: handlePasswordValidator }]}>
          <Input.Password autoComplete="new-password" onBlur={() => form.validateFields(["confirm_password"])} />
        </Form.Item>
        {/* ./ Password */}

        {/* Confirm Password */}
        <Form.Item<FieldType>
          label={t("common.confirm_password")}
          name="confirm_password"
          rules={[{ required: true, validator: handlePasswordValidator }]}
        >
          <Input.Password onBlur={() => form.validateFields(["password"])} />
        </Form.Item>
        {/* ./ Confirm Password */}

        <Form.Item<FieldType>
          name="terms_accepted"
          className="mb-0"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => {
                return value ? Promise.resolve() : Promise.reject(t("register.tos_error"));
              },
            },
          ]}
          validateTrigger={["onChange"]}
        >
          <Checkbox>
            <Trans i18nKey="common.terms_agree">
              I agree to Helvia.ai's
              <Link to={"https://helvia.ai/tos"} target="blank" rel="noopener noreferrer">
                Terms of Service
              </Link>
              .
            </Trans>
          </Checkbox>
        </Form.Item>
        <Form.Item<FieldType>
          name="privacy_policy_accepted"
          rules={[
            {
              validator: (_, value) => {
                return value ? Promise.resolve() : Promise.reject(t("register.privacy_policy_error"));
              },
            },
          ]}
          validateTrigger={["onChange"]}
          valuePropName="checked"
        >
          <Checkbox>
            <Trans i18nKey="common.privacy_agree">
              By submitting this form, I confirm that I have read and agreed to Helvia.ai's 
              <Link target="blank" rel="noopener noreferrer" to={"https://helvia.ai/privacy"}>
                Privacy Policy
              </Link>
              .
            </Trans>
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Button disabled={isLoading} type="primary" htmlType="submit">
            {t("common.register")}
          </Button>
          <div className="mt-3">
            <ErrorsComponent error={registrationError as unknown as IRequestError} />
          </div>
          <p className="mt-3">
            {t("registration.help_sign_in")}&nbsp;
            <Link to={NAV_LOGIN}>{t("common.sign_in")}</Link>
          </p>
        </Form.Item>
      </Form>
    </>
  );
};
