export const groupByFirstPartOfInputName = (inputObject: Record<string, any>) => {
  const groupedObject: Record<string, any> = {};

  for (const key in inputObject) {
    const [group, rest] = key.split(".");

    if (!groupedObject[group]) {
      groupedObject[group] = {};
    }

    groupedObject[group][rest] = inputObject[key];
  }

  return groupedObject;
};
