import sassVariables from '@/styles/_variables.module.scss'

export const SASS_VARS = sassVariables;

export const THEME_PROVIDER = {
  components: {
    Layout: {
      bodyBg: SASS_VARS.lightBgColor,
      footerBg: "#FAFAFE",
      headerBg: "#FAFAFE",
      headerPadding: "8px 50px",
      headerHeight: 48,
    },
    Button: {
      fontWeight: 500,
      controlHeight: 40,
      paddingContentHorizontal: 20,
      borderRadius: 10,
      primaryShadow: "none",
      dangerShadow: "none"
    },
    Menu: {
      darkItemBg: "transparent",
      activeBarHeight: 0,
      horizontalItemBorderRadius: 10,
      horizontalItemSelectedBg: SASS_VARS.lightGrayColor,
      horizontalItemSelectedColor: "#4D40E6",
      horizontalItemHoverBg: SASS_VARS.lightGrayColor,
      horizontalItemHoverColor: "#4D40E6",
    },
    Form: {
      labelColor: SASS_VARS.prominentColor,
      labelFontSize: 14, 
      labelHeight: 42,
      controlHeight: 42,
    },
    Checkbox: {
      fontSize: 16,
    },
    Input: {
      controlHeight: 42,
    },
    Select: {
      controlHeight: 42,
    },
    Descriptions: {
      padding: 8,
    },
    Avatar: {
      containerSize: 34,
      fontSize: 26,
    },
    Table: {
      borderColor: "#EBEAFD",
      headerBg: SASS_VARS.prominentBg,
      headerColor: SASS_VARS.prominentColor,
      rowHoverBg: SASS_VARS.prominentBg,
    },
    Tabs: {
      cardBg: "transparent",
      horizontalMargin: "0 0 4px 0"
    },
    Pagination: {
      itemActiveBg: SASS_VARS.lightGrayColor,
      // itemActiveBg: SASS_VARS.colorPrimary,
      itemSizeSM: 22
    }
  },
  token: {
    fontFamily: '"Ubuntu", sans-serif',
    // Seed Token
    colorText: SASS_VARS.prominentColor,
    colorPrimary: SASS_VARS.colorPrimary,
    colorLink: SASS_VARS.colorPrimary,
    colorSuccess: SASS_VARS.successColor,
    borderRadius: +SASS_VARS.borderRadius,
    fontSizeHeading1: 48,
    fontSizeHeading2: 32,
    fontSizeHeading3: 28,
    fontSizeHeading4: 22,
    fontSizeHeading5: 16,
    colorBorder: "#E1E1F3",
    lineWidth: 2
  },
}