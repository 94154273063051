export const validatePassword = (value: string, isRequired = true) => {
  if (!value && isRequired) {
    return "validation.password_required";
  }

  if (!value && !isRequired) {
    return null;
  }

  if (value.length < 8) {
    return "validation.password_length";
  }

  if (!/[A-Za-z]/.test(value) || !/\d/.test(value)) {
    return "validation.password_format";
  }

  return null;
};
